import React, { useRef, useState } from 'react';
import memeBackground from './houseofmemes.png';

const InteractiveBackground = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);
  const [lastTouch, setLastTouch] = useState(null);
  const containerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    
    // Calculate mouse position as percentage of container
    const xPercent = (e.clientX - rect.left) / rect.width;
    const yPercent = (e.clientY - rect.top) / rect.height;
    
    // Calculate movement range (larger numbers = more movement)
    const moveRange = isZoomed ? 10 : 30;
    
    // Transform percentage to movement value with increased range
    const x = (0.5 - xPercent) * moveRange;
    const y = (0.5 - yPercent) * moveRange;
    
    setPosition({ x, y });

    if (!isZoomed) {
      // Calculate distance from center for hover zoom effect
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      const distance = Math.sqrt(
        Math.pow(e.clientX - rect.left - centerX, 2) +
        Math.pow(e.clientY - rect.top - centerY, 2)
      );
      
      const maxDistance = Math.sqrt(Math.pow(centerX, 2) + Math.pow(centerY, 2));
      const newScale = 1 + (0.2 * (1 - distance / maxDistance));
      setScale(newScale);
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!containerRef.current || !e.touches[0]) return;

    const touch = e.touches[0];
    const rect = containerRef.current.getBoundingClientRect();
    
    const xPercent = (touch.clientX - rect.left) / rect.width;
    const yPercent = (touch.clientY - rect.top) / rect.height;
    
    const moveRange = isZoomed ? 10 : 30;
    const x = (0.5 - xPercent) * moveRange;
    const y = (0.5 - yPercent) * moveRange;
    
    setPosition({ x, y });
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setLastTouch({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = (e) => {
    if (!lastTouch) return;
    
    const touch = e.changedTouches[0];
    const deltaX = Math.abs(touch.clientX - lastTouch.x);
    const deltaY = Math.abs(touch.clientY - lastTouch.y);
    
    if (deltaX < 10 && deltaY < 10) {
      handleClick(e);
    }
    
    setLastTouch(null);
  };

  const handleClick = (e) => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const clickX = e.clientX || (e.changedTouches && e.changedTouches[0].clientX);
    const clickY = e.clientY || (e.changedTouches && e.changedTouches[0].clientY);

    if (isZoomed) {
      // Reset to default state
      setIsZoomed(false);
      setScale(1);
      setPosition({ x: 0, y: 0 });
    } else {
      // Zoom in on clicked/tapped position
      setIsZoomed(true);
      setScale(2.5);
      const xPercent = (clickX - rect.left) / rect.width;
      const yPercent = (clickY - rect.top) / rect.height;
      setPosition({
        x: (0.5 - xPercent) * 20,
        y: (0.5 - yPercent) * 20
      });
    }
  };

  // Reset position when mouse leaves
  const handleMouseLeave = () => {
    if (!isZoomed) {
      setPosition({ x: 0, y: 0 });
      setScale(1);
    }
  };

  return (
    <div 
      ref={containerRef}
      className="relative w-full h-screen overflow-hidden cursor-move"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div 
        className={`absolute w-[120%] h-[120%] left-[-10%] top-[-10%] transition-transform ${isZoomed ? 'duration-500' : 'duration-100'} ease-out`}
        style={{
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
        }}
      >
        <img
          src={memeBackground}
          alt="Interactive house"
          className="w-full h-full object-cover"
          draggable="false"
        />
      </div>
      <div className="absolute inset-0 bg-black/10 pointer-events-none" />
      
      {/* <div className="absolute bottom-4 right-4 bg-black/50 text-white px-3 py-1 rounded-full text-sm">
        {isZoomed ? 'Click to zoom out' : 'Click to zoom in'}
      </div> */}
    </div>
  );
};

export default InteractiveBackground;